const ColorScale = {
  mono: {
    black: '#00120B',
    white: 'rgba(255, 255, 255, 1.0)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  gray: {
    50: '#F7F8F9',
    100: '#E6E9ED',
    200: '#CCD3DB',
    300: '#ABB6C4',
    400: '#8999AC',
    500: '#5F758E',
    600: '#52677D',
    700: '#425366',
    800: '#334151',
    900: '#212C39',
  },
  green: {
    50: '#D5F3E6',
    100: '#AAE7CE',
    200: '#80DCB5',
    300: '#55D09C',
    400: '#2AC484',
    500: '#00B86B',
    600: '#009959',
    700: '#007B47',
    800: '#005C36',
    900: '#003D24',
  },
  blue: {
    25: '#F6FAFF',
    50: '#DAE8FF',
    100: '#B5D1FF',
    200: '#90BBFF',
    300: '#6BA4FF',
    400: '#468DFF',
    500: '#2176FF',
    600: '#1A5ECC',
    700: '#144799',
    800: '#0D2F66',
    900: '#0B2755',
  },
  yellow: {
    50: '#FDF3D5',
    100: '#FCE7AA',
    200: '#FADB80',
    300: '#F8CF55',
    400: '#F7C32A',
    500: '#F5B700',
    600: '#CC9900',
    700: '#A37A00',
    800: '#7B5C00',
    900: '#523D00',
  },
  red: {
    50: '#F9DFDE',
    100: '#F4BFBD',
    200: '#EEA09D',
    300: '#E8807C',
    400: '#E3605B',
    500: '#DD403A',
    600: '#B83530',
    700: '#932B27',
    800: '#6F201D',
    900: '#4A1513',
  },
  orange: {
    50: '#FFF0EE',
    100: '#FFD0C9',
    200: '#FFB0A4',
    300: '#FF8F7E',
    400: '#FF6F59',
    500: '#DD5844',
    600: '#BB4331',
    700: '#993121',
    800: '#772215',
    900: '#55150B',
  },
  purple: {
    50: '#F9F4FF',
    100: '#ECDCFF',
    200: '#E0C5FF',
    300: '#D3ADFF',
    400: '#C795FF',
    500: '#AE78EB',
    600: '#8C57C9',
    700: '#6E3BA7',
    800: '#522585',
    900: '#391463',
  },
};

// TODO: Depricate these colors

const COMPONENT_COLOR_SCALE = [
  '#f07969',
  '#f79586',
  '#fcb0a4',
  '#ffcac3',
  '#ffe5e2',
  '#f9beb8',
  '#f09692',
  '#e36d70',
  '#d43d51',
];

const CUSTOM_COLORS = {
  SLACK: '#4A154B',
  STRIPE: '#645AFF',
  ZENDESK: '#17494D',
};

const getColorScale = (i: number) => {
  return COMPONENT_COLOR_SCALE[i % COMPONENT_COLOR_SCALE.length];
};

export {
  COMPONENT_COLOR_SCALE as ComponentColorScale,
  CUSTOM_COLORS as CustomColors,
  getColorScale,
  ColorScale,
};
