import { CSSObject } from '@emotion/react';

const globalStyles: CSSObject = {
  html: {
    height: '100%',
  },
  body: {
    margin: 0,
    height: '100%',
    overflowX: 'hidden',
  },
  '#react-app': {
    height: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  fieldset: {
    border: 0,
    padding: 0,
  },
};

export { globalStyles };
