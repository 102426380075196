import globalStore from '..';
import { RootState } from '../types';

/**
 * @param selector - Selects a particular object from the store state to reason about
 * @param condition - Predicate which returns true to resolve the promise and false to continue waiting
 */
function whenState<T>(
  store: typeof globalStore,
  selector: (state: RootState) => T,
  condition: (t: T) => boolean,
): Promise<T>;
/**
 * Overload that takes a type guard to assert some extra information about the final state
 */
function whenState<T, U extends T>(
  store: typeof globalStore,
  selector: (state: RootState) => T,
  condition: (t: T) => t is U,
): Promise<U>;
function whenState<T>(
  store: typeof globalStore,
  selector: (state: RootState) => T,
  condition: (t: T) => boolean,
) {
  return new Promise<T>((resolve) => {
    // Check initial state
    const initialTarget = selector(store.getState());
    if (condition(initialTarget)) {
      resolve(initialTarget);
      return;
    }

    // If initial state doesn't match condition, we need to subscribe to the store
    const unsubscribe = store.subscribe(() => {
      const target = selector(store.getState());
      if (condition(target)) {
        resolve(target);
        unsubscribe();
      }
    });
  });
}

export default whenState;
