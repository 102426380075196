import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/system';

const SvgIcon = styled((props: SvgIconProps) => (
  <MuiSvgIcon
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  />
))(({ fill }) => ({
  width: '16px',
  height: '16px',
  fill,
}));

export { SvgIcon };
