import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { notifierSelector } from '@/store/Selectors';
import { useAppDispatch } from '@/store/hooks';
import { dequeueNotification } from '@/store/slices/ui/Notifier';
import { SnackbarKey, useSnackbar } from 'notistack';

let displayed: SnackbarKey[] = [];

function storeDisplayed(id: SnackbarKey) {
  displayed = [...displayed, id];
}

function removeDisplayed(id: SnackbarKey) {
  displayed = [...displayed.filter((key) => id !== key)];
}

function useNotifier() {
  const dispatch = useAppDispatch();
  const { notifications } = useSelector(notifierSelector);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.forEach(({ key, message, variant, autoHideDuration }) => {
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) {
        return;
      }

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        variant,
        autoHideDuration,
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(dequeueNotification({ key: myKey }));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [dispatch, notifications, enqueueSnackbar]);
}

export default useNotifier;
