import apolloClient from '@/apollo';

import { UserDocument, useUserQuery } from './__generated__/queries.generated';

function useUserLoading() {
  const { data, ...rest } = useUserQuery({
    fetchPolicy: 'cache-first',
  });

  return { user: data?.user, ...rest };
}

function useUser() {
  const data = useUserLoading();

  if (!data.user) {
    throw new Error('User not loaded');
  }

  return data.user;
}

function getUserFromCache() {
  const data = apolloClient.readQuery({
    query: UserDocument,
  });

  return data?.user;
}

export { getUserFromCache, useUserLoading };
export default useUser;
