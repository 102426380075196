import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Notification, NotifierState } from './types';

const initialState = {
  notifications: [],
} as NotifierState;

// notistack default - https://notistack.com/api-reference#mutual-props
const DEFAULT_AUTO_HIDE_DURATION = 5000;

const Notifier = createSlice({
  name: 'data-resolver',
  initialState,
  reducers: {
    enqueueNotification(state, action: PayloadAction<Notification>) {
      const {
        key,
        message,
        variant,
        autoHideDuration = DEFAULT_AUTO_HIDE_DURATION,
      } = action.payload;
      state.notifications = [
        ...state.notifications,
        {
          message,
          variant,
          autoHideDuration,
          key: key || (new Date().getTime() + Math.random()).toString(),
        },
      ];
    },
    dequeueNotification(state, action) {
      const { key } = action.payload;
      state.notifications = state.notifications.filter((notification) => notification.key !== key);
    },
  },
});

export const { enqueueNotification, dequeueNotification } = Notifier.actions;

export default Notifier.reducer;
