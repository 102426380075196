import { useState } from 'react';

import { useTimeoutFn } from 'react-use';

import { convertToLocalTime } from '@/utils/date';
import moment from 'moment';

function getNextRenderMS(date: Date) {
  const now = moment();
  const diffInSeconds = now.diff(moment(date), 'seconds');
  const diffInMinutes = diffInSeconds / 60;
  const diffInHours = diffInMinutes / 60;

  if (diffInHours > 24) {
    return null;
  }
  if (diffInHours > 1) {
    return (60 - (diffInMinutes % 60)) * 60 * 1000;
  }
  if (diffInMinutes > 1) {
    return (60 - (diffInSeconds % 60)) * 1000;
  }
  return 1000;
}

function useDisplayDate(date: Date | string): string {
  const [localizedDate, setLocalizedDate] = useState(() => convertToLocalTime(date));

  useTimeoutFn(() => setLocalizedDate(convertToLocalTime(date)), getNextRenderMS(localizedDate));

  return moment(date).fromNow();
}

export default useDisplayDate;
