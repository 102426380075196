import { createContext, useContext } from 'react';

import { RoleTypes } from '@/pages/Settings/Tabs/Members/types';

import { useUserLoading } from './useUser';

export const AsOperatorContext = createContext<boolean | undefined>(undefined);

function useAsOperator() {
  const { user } = useUserLoading();

  const asOperatorContextValue = useContext(AsOperatorContext);
  return asOperatorContextValue ?? user?.roleName === RoleTypes.OPERATOR_ROLE;
}

export default useAsOperator;
