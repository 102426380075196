import isEmpty from 'lodash/isEmpty';
import nthArg from 'lodash/nthArg';
import values from 'lodash/values';

import { ReleaseEnv } from '@/types/releaseEnv';

import { WorkflowEntity, WorkflowIdentifier } from './slices/entities/Workflows/types';
import { WorkletEntity, WorkletIdentifier } from './slices/entities/Worklets/types';
import { RootSelector, RootState } from './types';
import createDeepEqualSelector from './utils/DeepEqualSelector';

function entitySelector<S>(collectionSelector: RootSelector<Record<string, S>>) {
  return (state: RootState, id: string) => {
    if (isEmpty(id)) {
      return null;
    }
    return collectionSelector(state)[id];
  };
}

// Entity Selectors
export const nodesSelector = (state: RootState, releaseEnv: ReleaseEnv) =>
  state.entities.nodes[releaseEnv];
export const nodeSelector = (state: RootState, releaseEnv: ReleaseEnv, nodeId: string) =>
  state.entities.nodes[releaseEnv][nodeId];

export const workletsSelector = (state: RootState, releaseEnv: ReleaseEnv) =>
  state.entities.worklets[releaseEnv];
export const workletSelector = (state: RootState, workletIdentifier: WorkletIdentifier) =>
  workletsSelector(state, workletIdentifier.releaseEnv)[workletIdentifier.workletId];

export const workflowsSelector = (state: RootState, releaseEnv: ReleaseEnv) =>
  state.entities.workflows[releaseEnv];
export const workflowSelector = (state: RootState, workflowIdentifier: WorkflowIdentifier) =>
  workflowsSelector(state, workflowIdentifier.releaseEnv)[workflowIdentifier.workflowId];

export const ontologyClassesSelector = (state: RootState) => state.entities.classes;
export const ontologyClassSelector = (state: RootState, id: string) =>
  ontologyClassesSelector(state)[id];

export const dataSourcesSelector = (state: RootState) => state.entities.dataSources.sources;
export const dataSourceSelector = entitySelector(dataSourcesSelector);
export const sageMakersModelsSelector = (state: RootState) => state.entities.dataSources.smModels;
export const sageMakerModelsSelector = entitySelector(sageMakersModelsSelector);

export const workflowFromNodeIdSelector = (
  state: RootState,
  nodeId: string,
  releaseEnv: ReleaseEnv,
): WorkflowEntity => {
  const workflows = workflowsSelector(state, releaseEnv);
  return values(workflows).find((workflow) =>
    workflow.worklets.some((workletId) => {
      const worklet = workletSelector(state, { workletId, releaseEnv });
      return worklet.nodes.some((workletNodeId) => workletNodeId === nodeId);
    }),
  );
};

export const workflowFromWorkletIdSelector = (
  state: RootState,
  workletIdentifier: WorkletIdentifier,
): WorkflowEntity => {
  const workflows = workflowsSelector(state, workletIdentifier.releaseEnv);
  return values(workflows).find((workflow) =>
    workflow.worklets.some((wid) => wid === workletIdentifier.workletId),
  );
};

export const workletFromNodeIdSelector = (
  state: RootState,
  nodeId: string,
  releaseEnv: ReleaseEnv,
): WorkletEntity => {
  const worklets = workletsSelector(state, releaseEnv);
  return values(worklets).find((worklet) => worklet.nodes.some((id) => id === nodeId));
};

export const queriesSelector = (state: RootState) => state.entities?.queries;
export const querySelector = (state: RootState, queryId: string) => {
  const queries = queriesSelector(state);
  return queries[queryId];
};

export const viewsSelector = (state: RootState) => state.entities.views;

export const externalConnectionsSelector = (state: RootState) => state.entities.externalConnections;

export const onboardingChecklistSelector = (state: RootState) => state.entities.onboardingChecklist;

// View Selectors
export const appViewSelector = (state: RootState) => state.ui.appView;
export const workflowViewSelector = (state: RootState) => state.ui.workflowView;
export const consoleViewSelector = (state: RootState) => state.ui.consoleView;
export const modalsSelector = (state: RootState) => state.ui.modals;
export const applicationViewSelector = (state: RootState) => state.ui.applicationsView;
export const notifierSelector = (state: RootState) => state.ui.notifier;
export const queryDialogSelector = (state: RootState) => state.ui.queryDialog;
export const workletViewSelector = (state: RootState) => state.ui.workletView;
export const classViewSelector = (state: RootState) => state.ui.classView;
export const dataSourcesViewSelector = (state: RootState) => state.ui.dataSourcesView;
export const shareViewSelector = (state: RootState) => state.ui.shareView;
export const shownReleaseVersionSelector = (state: RootState) =>
  state.router.location.query.release;

export const workletIdSelector = createDeepEqualSelector(
  [workflowSelector, (state: RootState) => state.entities.worklets, nthArg(1)],
  // Only include worklets that are loaded
  (workflow, worklets, workflowIdentifier: WorkflowIdentifier) =>
    workflow.worklets.filter((workletId) => !!worklets[workflowIdentifier.releaseEnv][workletId]),
);
