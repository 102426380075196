import {
  MembersPageInvitedUserFragment,
  MembersPageUserFragment,
} from './__generated__/fragment.generated';

type User = MembersPageInvitedUserFragment | MembersPageUserFragment;

enum RoleTypes {
  ADMIN_ROLE = 'admin',
  CREATOR_ROLE = 'creator',
  OPERATOR_ROLE = 'operator',
}

enum ActionType {
  REMOVE = 'remove',
  APPROVE = 'approve',
  DENY = 'deny',
}

enum MemberFilterType {
  ALL = 'all',
  EXISTING = 'existing',
  NEEDS_APPROVAL = 'needs_approval',
  INVITED = 'invited',
}

type FilterNameType = {
  [key in MemberFilterType]: string;
};

type FilterMapType = {
  [key in MemberFilterType]: (user: User) => boolean;
};

type RolePromptType = Record<RoleTypes, string>;

type UseWorkspaceUsers = {
  loading: boolean;
  users: Array<User>;
  refetch: () => void;
};

export { RoleTypes, ActionType, MemberFilterType };

export type { User, UseWorkspaceUsers, RolePromptType, FilterMapType, FilterNameType };
