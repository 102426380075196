/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UserFragment = { __typename?: 'UserType', id: string, status: Types.ApprovalStatus | null | undefined, username: string, name: string | null | undefined, email: string | null | undefined, created: any, companyName: string | null | undefined, firstName: string | null | undefined, lastName: string | null | undefined, profileUse: string | null | undefined, linkedinProfile: string | null | undefined, hasCompletedProfile: boolean, workspaceName: string | null | undefined, roleName: string | null | undefined, applicationsAndDataEnabled: boolean | null | undefined };

export const UserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","alias":{"kind":"Name","value":"companyName"},"name":{"kind":"Name","value":"company_name"}},{"kind":"Field","alias":{"kind":"Name","value":"firstName"},"name":{"kind":"Name","value":"first_name"}},{"kind":"Field","alias":{"kind":"Name","value":"lastName"},"name":{"kind":"Name","value":"last_name"}},{"kind":"Field","alias":{"kind":"Name","value":"profileUse"},"name":{"kind":"Name","value":"profile_use"}},{"kind":"Field","alias":{"kind":"Name","value":"linkedinProfile"},"name":{"kind":"Name","value":"linkedin_url"}},{"kind":"Field","alias":{"kind":"Name","value":"hasCompletedProfile"},"name":{"kind":"Name","value":"has_completed_profile"}},{"kind":"Field","alias":{"kind":"Name","value":"workspaceName"},"name":{"kind":"Name","value":"workspace_name"}},{"kind":"Field","name":{"kind":"Name","value":"username"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","alias":{"kind":"Name","value":"roleName"},"name":{"kind":"Name","value":"role_name"}},{"kind":"Field","alias":{"kind":"Name","value":"applicationsAndDataEnabled"},"name":{"kind":"Name","value":"applications_and_data_enabled"}},{"kind":"Field","name":{"kind":"Name","value":"created"}}]}}]} as unknown as DocumentNode<UserFragment, unknown>;
export const namedOperations = {
  Fragment: {
    UserFragment: 'UserFragment'
  }
}
