import { useEffect, useState } from 'react';

const downloadedImages: Record<string, boolean> = {};

function shouldLoadImage(src: string) {
  return src && !src.startsWith?.('data:image') && !downloadedImages[src];
}

function useImage(src: string) {
  const [loaded, setLoaded] = useState(!shouldLoadImage(src));
  const [error, setError] = useState(null);

  useEffect(() => {
    const img = new Image();
    if (shouldLoadImage(src)) {
      setLoaded(false);
      img.src = src;
      img.onload = () => {
        downloadedImages[src] = true;
        setLoaded(true);
        setError(null);
      };
      img.onerror = (err) => setError(err);
    } else {
      setLoaded(true);
      setError(null);
    }

    return () => {
      img.onerror = null;
      img.onload = null;
      img.remove();
    };
  }, [src]);

  return [loaded, error];
}

export default useImage;
