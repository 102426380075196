import isEqual from 'lodash/isEqual';

import { DateRange } from '@/sections/Application/Logs/types';
import moment, { Moment } from 'moment';

function dateDisplay(d: moment.Moment): string {
  const now = moment();
  if (now.diff(d, 'minutes') < 1) {
    return d.fromNow();
  }

  if (now.isSame(d, 'week')) {
    if (now.diff(d, 'days') < 2) {
      return d.calendar();
    }

    return d.format('ddd [at] h:mm A');
  }

  if (now.diff(d, 'days') < 365) {
    return d.format('MMM Do [at] h:mm A');
  }

  // if (now.diff(d, 'days') >= 365)
  return d.format('MMM Do, YYYY [at] h:mm A');
}

function convertToLocalTime(date: string | Date): Date {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const converted = new Date(date).toLocaleString('en-US', { timeZone });
  return new Date(converted);
}

function getDateString(dateStr: string | Moment | Date): string {
  const date = moment(typeof dateStr === 'string' ? convertToLocalTime(dateStr) : dateStr);
  const isCurrentDay = date.isSame(new Date(), 'day');

  if (isCurrentDay) {
    return date.format('[Today at] hh:mma');
  }

  const isCurrentYear = date.isSame(new Date(), 'year');
  return date.format(isCurrentYear ? 'MMM DD' : 'MMM DD, YYYY');
}

function formatBillableTime(minutes: number): string {
  if (minutes > 60) {
    return `${Math.floor(minutes / 60).toLocaleString('en-US')}h ${minutes % 60}m`;
  }
  return `${minutes}m`;
}

const pickersShortcutsItemGetValueParams = {
  // TODO (Suren): In case of our date picker, we don't need to check if the date is valid or not.
  isValid: () => true,
};

function convertToDate(dateRange?: [Moment, Moment]): [Date, Date] {
  return dateRange?.map((date) => date?.toDate()) as [Date, Date];
}

function convertToMoment(dateRange?: [Date, Date]): [Moment, Moment] {
  return dateRange?.map((date) => moment(date)) as [Moment, Moment];
}

function convertMomentToUnix(dateRange: [Moment, Moment]): [number, number] {
  return dateRange.map((date) => date.unix()) as [number, number];
}

function convertUnixToMoment(dateRange: [number, number]): [Moment, Moment] {
  return dateRange.map((date) => moment.unix(date)) as [Moment, Moment];
}

function getDateRange(from: Moment, to?: Moment): DateRange {
  if (!to) {
    return [from.startOf('day'), moment(from).endOf('day')];
  }
  return [from, to];
}

function isRangesEqual(range1: DateRange, range2: DateRange) {
  const mapper = (date: Moment) => date.milliseconds(0).unix();
  return isEqual(range1.map(mapper), range2.map(mapper));
}

/**
 * [to] might be undefined if user selects a single day (not date range)
 * if [to] is defined that means we have a date range [from-to], so we need to check if [to] is the current day for auto update
 * if [to] is not defined we need to check if the selected day (which is [from]) is the current day for auto update
 */
function shouldAutoUpdate(from: Moment, to?: Moment): boolean {
  const now = moment();
  return (to || from).isSame(now, 'day');
}

export {
  dateDisplay,
  getDateString,
  convertToLocalTime,
  formatBillableTime,
  pickersShortcutsItemGetValueParams,
  convertToDate,
  convertToMoment,
  getDateRange,
  isRangesEqual,
  shouldAutoUpdate,
  convertMomentToUnix,
  convertUnixToMoment,
};
